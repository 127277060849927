<template>
  <section class="accent py-8">
    <v-container>
      <v-row>
        <v-col>
          <v-img src="@/assets/images/maneirissima_quem_somos.png"></v-img>
        </v-col>
        <v-col>
          <h1 class="secondary--text">Quem Somos</h1>
          <p class="white--text">A história da Maneiríssima começou na vontade de fazer algo que unisse amigos em torno da mesa. E no calor do Rio, uma boa cerveja é a pedida certa para isso!
  Uma panela, bons ingredientes, cuidado, paciência e muitos amigos para provar e aprovar. E no meio da roda, eis que alguém grita: “Maneiríssima!” Foi assim. A Maneiríssima surgiu, ganhou seus fãs e se tornou companheira constante nos encontros da galera.
  Daí, para virar um projeto, foi um pulo! E para homenagear este sabor de gente boa reunida, a Maneiríssima se transforma na mais nova cervejaria “cigana” do mercado.
  </p>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
  export default {
    name: 'AboutUs',
    data: () => ({
    })
  }
</script>