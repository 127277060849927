<template>
  <v-layout py-5>
    <v-flex xs4 md3 class="the-beer--align-text">
      <h3 class="white--text text-uppercase">
        {{ label }}
      </h3>
    </v-flex>
    <v-flex xs8 md9>
      <v-layout>
        <v-flex v-for="i in total" :key="i">
          <v-img
            :src="src(i)"
            :width="size"
            contain
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: 'Score',
  props: ['label', 'total', 'value', 'icon', 'size'],
  methods: {
    src (index) {
      let suffix = 'off'

      if (index < this.value) {
        suffix = 'on'
      }
      return require(`@/assets/icons/icone_${this.icon}_${suffix}.svg`) },
  }
}
</script>