import axios from 'axios'

const http = axios.create({
  baseURL: process.env.VUE_APP_CONTACT_URL || 'https://maneirissima.herokuapp.com',
  headers: {
    Authorization: process.env.VUE_APP_CONTACT_AUTHORIZATION || '123456'
  }
})

export default {
  send (form) {
    return http.post('/contact', form)
      .then(res => res.data)
  }
}