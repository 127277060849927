<template>
  <section class="primary py-8">
    <v-container>
      <v-row>
        <v-col>
          <v-img src="@/assets/images/beer_dictionary.svg"></v-img>
        </v-col>
        <v-col>
          <div v-for="(definition, idx) of definitions" :key="idx">
            <h1 class="secondary--text font-weight-bold">{{definition.word}}</h1>
            <p class="secondary--text" v-html="definition.explanation"></p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
  export default {
    name: 'BeerDictionary',
    data: () => ({
      definitions: [
        {
          word: 'MANEIRA',
          explanation: 'Situação ou algo legal, interessante, que chama atenção das pessoas.<br />De boa qualidade.'
        },
        {
          word: 'Sufixo /íssima/',
          explanation: 'Termo utilizado no sentido de muito, superlativo.'
        },
        {
          word: 'MANEIRÍSSIMA',
          explanation: 'Muito legal! De extrema qualidade! A cerveja que harmoniza com você.'
        }
      ]
    })
  }
</script>