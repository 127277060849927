<template>
  <section>
    <banner />
    <intro />
    <about-us id="quem-somos" />
    <beer-dictionary />
    <the-beer id="a-cerveja" />
    <delivery id="entrega-programada" />
  </section>
</template>

<script>
import { Banner, Intro, AboutUs, BeerDictionary, TheBeer, Delivery } from '@/components'

export default {
  components: {
    Banner,
    Intro,
    AboutUs,
    BeerDictionary,
    TheBeer,
    Delivery,
  },
  mounted () {
    let hash = window.location.hash.replace('/','')
    if (hash === '#') hash = 0

    if (hash) {
      setTimeout(() => this.$vuetify.goTo(hash), 600)
    }
  }
}
</script>
