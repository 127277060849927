<template>
  <v-form v-model="form.valid" :disabled="form.loading" @submit.prevent="send" ref="contactForm">
    <h1 class="text-uppercase secondary--text">Peça a sua!</h1>
    <v-alert v-if="response.message" :type="response.type">
      {{ response.message }}
    </v-alert>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field v-model="contact.firstName" solo label="Nome" required :rules="[rules.required]"/>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="contact.lastName" solo label="Sobrenome" required :rules="[rules.required]"/>
      </v-col>
    </v-row>
    <v-row class="mt-n5 mb-n2">
      <v-col cols="12" md="6">
        <v-text-field v-model="contact.email" solo label="Email" required :rules="[rules.required, rules.email]"/>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="contact.phone" solo label="Telefone" required :rules="[rules.required, rules.phone]"/>
      </v-col>
    </v-row>
    <v-text-field v-model="contact.address" solo label="Endereço" required :rules="[rules.required]"/>
    <v-textarea v-model="contact.message" rows="4" solo label="Mensagem" :counter="600" required :rules="[rules.required, rules.message]"/>
    <v-btn
      color="success float-right"
      type="submit"
      :loading="form.loading">
      Enviar
    </v-btn>
  </v-form>
</template>
<script>
import api from '@/api/contact'

export default {
  name: 'ContactForm',
  data: () => ({
    form: {
      valid: false,
      loading: false
    },
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      message: ''
    },
    response: {
      message: '',
      type: ''
    },
    rules: {
      required: v => !!v || 'Campo obrigatório',
      message:  v => v.length <= 600 || 'Max. 600 caracteres',
      email: v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Email inválido',
      phone: v => /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/.test(v) || 'Telefone inválido'
    }
  }),
  methods: {
    async send () {
      if (!this.form.valid) {
        return this.$refs.contactForm.validate()
      }

      try {
        this.response.message = null
        this.form.loading = true
        const { message } = await api.send(this.contact)
        this.response.message = message
        this.response.type = 'success'
      }
      catch (error) {
        this.response.message = 'Não foi possível enviar essa mensagem. Favor tente novamente mais tarde.'
        this.response.type = 'error'
      }
      finally {
        this.form.loading = false
      }
    }
  }
}
</script>