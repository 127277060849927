<template>
  <section class="accent">
    <v-container class="pt-16 pb-8">
      <v-row align="center">
        <v-col>
          <p class="white--text">Claro que estamos falando do Rio de Janeiro. Também...
    A Maneiríssima é uma cerveja de alma carioca, com sabor de encontro entre amigos! Mesa cheia, galera ao redor, falando de tudo e com todos ao mesmo tempo, mas se entendendo perfeitamente!
    Uma cerveja que harmoniza com você e com tudo o que você curte! Feita para lhe acompanhar onde quer que seja, com quem quer que seja e em qualquer situação! Sempre cai bem!</p>
        </v-col>
        <v-col>
          <v-img src="@/assets/images/maneirissima_home.png"></v-img>
        </v-col>
      </v-row>
      <v-row class="pt-16">
        <v-col>
          <v-img height="165px" contain src="@/assets/images/brewing_company.svg"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
  export default {
    name: 'Intro',
    data: () => ({
    })
  }
</script>