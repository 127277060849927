<template>
  <section class="primary py-8">
    <v-container>
      <v-row align="center" justify="center">
        <v-img src="@/assets/icons/icone_bussola.svg" height="150px" contain></v-img>
      </v-row>
      <div class="my-4">
          <h1 class="text-uppercase secondary--text">Entrega Programada Maneiríssima</h1>
          <small class="secondary--text">Por enquanto, entregas restritas à Zona Sul do Rio de Janeiro.</small>
      </div>
      <v-row>
        <v-col>
          <p class="white--text mb-4">
            Chega de abrir a geladeira e não encontrar a sua Maneiríssima estupidamente gelada!
            Chegou a Entrega Programada Maneiríssima. Basta mandar e a gente leva a Maneiríssima até você. Você escolhe
            e a gente entrega – entre quinta e sexta feira – para que a alegria e refrescância do seu fim de semana
            estejam garantidas! Depois, de encomendar com a gente, é só mandar o convite para a galera, preparar a casa,
            esperar os amigos chegarem para se divertir.
          </p>
        </v-col>
        <v-col>
          <v-img src="@/assets/images/man_keg_e_grwaler.png"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>

export default {
  name: 'Delivery',
  data: () => ({}),
}
</script>
