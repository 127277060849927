<template>
  <section class="accent py-8">
    <v-container>
      <v-row align="center" justify="center">
        <v-img src="@/assets/images/quality_beer.svg" height="150px" contain />
      </v-row>
      <v-row>
        <v-col>
          <h1 class="text-uppercase secondary--text">A Cerveja</h1>
          <p class="white--text">
            Nosso primeiro rótulo, uma Blond Ale com aroma leve de malte e de lúpulo, notas frutadas e
            uma coloração amarelo ouro, como o sol que brilha o ano inteiro na Cidade Maravilhosa.
            Límpida e com colarinho branco e cremoso, deixa um amargor médio/baixo na boca.
            Com drikability e refrescância incomparáveis.
          </p>
          <h3 class="text-uppercase secondary--text"> Maneiríssima Blond ale </h3>
          <v-layout py-5>
            <v-flex xs4 md3>
              <h3 class="white--text text-uppercase the-beer--align-text">Álcool</h3>
            </v-flex>
            <v-flex xs8 md9>
              <v-img src="@/assets/images/alcool_bar_level.svg" contain />
            </v-flex>
          </v-layout>
          <score
            v-for="(score, idx) of scores"
            v-bind="score"
            :key="idx"
          />
          <h3 class="text-uppercase white--text font-weight-bold pt-8">ABV 5.5 | IBU 28</h3>
        </v-col>
        <v-col>
          <v-img src="@/assets/images/man_garrafas.png" contain/>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
import Score from './Score'

export default {
  name: 'TheBeer',
  components: { Score },
  data: () => ({
    scores: [
      {
        label: 'Lúpulo',
        value: 3,
        total: 5,
        size: '45px',
        icon: 'lupulo'
      },
      {
        label: 'Malte',
        value: 4,
        total: 5,
        size: '32px',
        icon: 'malte'
      },
    ]
  })
}
</script>