import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#08383A',
        secondary: '#FFEE53',
        accent: '#20C2AF',
        success: '#30CC5B'
      }
    },
    options: {
      customProperties: true,
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
})
