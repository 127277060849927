<template>
  <v-app>
    <default-layout>
      <home-page />
    </default-layout>
  </v-app>
</template>

<script>
import DefaultLayout from '@/layouts/Default'
import HomePage from '@/view/home/Index'

export default {
  name: 'App',
  components: {
    DefaultLayout,
    HomePage
  }
}
</script>
