<template>
  <div>
    <navbar />

    <v-main>
      <slot />
    </v-main>

    <m-footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import MFooter from '@/components/Footer'

export default {
  components: {
    Navbar,
    MFooter
  }
}
</script>
