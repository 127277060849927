<template>
  <v-app-bar
    app
    color="primary"
    class="m-navbar"
    dark
    elevate-on-scroll
    :height="sizes.navbar"
    v-scroll="(e) => offsetTop = e.target.documentElement.scrollTop"
  >
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-toolbar-items>
        <v-btn
          text
          :key="idx"
          v-for="(link, idx) of navLinks.slice(0,2)"
          class="m-navbar__link font-weight-bold"
          color="secondary"
          :href="link.href"
          @click="link.click"
        >
          {{ link.text }}
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-title class="px-md-2">
        <div>
          <v-img
            :src="require('@/assets/logo/maneirissima_logo.png')"
            contain
            v-bind="sizes.logo"
            @click="goHome"
            transition="scale-transition"
            style="cursor: pointer;"
          />
        </div>
      </v-toolbar-title>
      <v-toolbar-items>
        <v-btn
          text
          :key="idx"
          v-for="(link, idx) of navLinks.slice(2,3)"
          class="m-navbar__link font-weight-bold"
          color="secondary"
          :href="link.href"
          @click="link.click"
        >
          {{ link.text }}
        </v-btn>
      </v-toolbar-items>
    </template>
    <mobile-menu
      v-else
      :links="navLinks"
      :socials="socialLinks"
    />

    <v-btn
      v-for="(link, idx) of socialLinks"
      :key="idx"
      icon
      :href="link.href"
      target="_blank"
    >
      <v-icon color="secondary" size="30">
        {{ link.icon }}
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import MobileMenu from '@/components/MobileMenu'

export default {
  name: 'Navbar',
  components: {
    MobileMenu
  },
  data: (vm) => ({
    offsetTop: 0,
    links: [
      {
        text: 'Quem somos',
        href: '#/quem-somos',
        click: () => vm.$vuetify.goTo('#quem-somos')
      },
      {
        text: 'A Cerveja',
        href: '#/a-cerveja',
        click: () => vm.$vuetify.goTo('#a-cerveja')
      },
      {
        text: 'Entrega Programada',
        href: '#/entrega-programada',
        click: () => vm.$vuetify.goTo('#entrega-programada')
      }
    ]
  }),
  computed: {
    socialLinks () {
      return [
        {
          icon: 'mdi-facebook',
          href: 'https://www.facebook.com/maneirissima'
        },
        {
          icon: 'mdi-instagram',
          href: 'https://www.instagram.com/maneirissima_oficial'
        }
      ]
    },
    navLinks () {
      return this.links
    },
    sizes () {
      let height = 180 - this.offsetTop * 0.6
      let marginTop = 4*22 - this.offsetTop
      let navbar = 120 - this.offsetTop * 0.15

      if (height < 90) {
        height = 70
        navbar = 80
      }
      if (marginTop < 0) marginTop = 0

      if (this.$vuetify.breakpoint.smAndDown) {
        navbar = null
      }


      return {
        logo: {
          height,
          style: {
            marginTop: `${marginTop}px`
          }
        },
        navbar
      }
    }
  },
  methods: {
    goHome () {
      this.$vuetify.goTo(0)
      window.location.hash = '#/'
    }
  }
}
</script>

<style lang="scss">
$hover-transition: all cubic-bezier(0.215, 0.610, 0.355, 1) 0.6s;

.m-navbar {
  // navbar link
  &__link {
    transition: $hover-transition;

    &:hover, &--active {
      background: var(--v-success-base) !important;
      .v-btn__content { color:white !important; }
    }
  }

  .v-toolbar__content {
    @media #{map-get($display-breakpoints, 'lg-and-down')} {
      max-width: map-get($container-max-widths, 'lg') !important;
    }
    @media #{map-get($display-breakpoints, 'lg-only')} {
      max-width: map-get($container-max-widths, 'xl') !important;
    }
    @media #{map-get($display-breakpoints, 'xl-only')} {
      max-width: map-get($container-max-widths, 'xl') !important;
    }

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      justify-content: space-between;
    }



    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
}
</style>
