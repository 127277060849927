<template>
  <div class="text-center">
    <v-dialog v-model="dialog" fullscreen content-class="m-mobile-menu">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" class="mr-auto">
          <v-icon color="secondary" size="30">
            mdi-menu
          </v-icon>
        </v-btn>
      </template>

      <v-card tile color="primary" dark>
        <v-card-title class="headline" primary-title>
          <v-btn icon @click="dialog = false" color="white">
            <v-icon size="30" color="secondary">
              mdi-close
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-for="(link, idx) of socials"
            :key="idx"
            icon
            :href="link.href"
            target="_blank"
          >
            <v-icon color="secondary" size="30">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-0">
          <v-btn
            v-for="(link, idx) of siteLinks"
            v-bind="link"
            large block
            @click="dispatchAction(link)"
            min-width="130px" text
            color="primary"
            class="m-navbar__link m-mobile-link font-weight-bold pa-8 secondary--text"
            :key="idx"
          >
            {{ link.text }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'MobileMenu',
    props: {
      links: Array,
      socials: Array
    },
    data: () => ({
      dialog: false
    }),
    computed: {
      siteLinks () {
        const links = this.links
        links.unshift()
        return [
          {
            text: 'Home',
            href: '#/',
            click: () => this.$vuetify.goTo(0)
          },
          ...this.links
        ]
      }
    },
    methods: {
      dispatchAction(link) {
        this.dialog = false
        if (typeof link.click === 'function') {
          link.click()
        }
      }
    }
  }
</script>

<style lang="scss">
.m-mobile-link {
  .v-btn__content {
    display: block;
    text-align: left;
  }
}
</style>