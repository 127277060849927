<template>
  <section class="m-banner">
    <v-carousel
      hide-delimiters
      :show-arrows="false"
      class="primary"
    >
      <v-carousel-item
        v-for="(item, idx) of items"
        :key="idx"
        v-bind="item"
      >
        <v-container fill-height>
          <v-layout fill-height align-center>
            <v-flex xs12 md6 class="text-uppercase">
              <h1> É alegria! </h1>
              <h1> É natureza! </h1>
              <h1> É maravilhosa! </h1>
              <h1 class="font-weight-bold"> É Maneiríssima! </h1>
            </v-flex>
          </v-layout>
        </v-container>
      </v-carousel-item>
    </v-carousel>
    <v-layout class="m-banner__mobile-logo" v-if="$vuetify.breakpoint.smAndDown">
      <v-flex>
         <v-img
            :src="require('@/assets/logo/maneirissima_logo.png')"
            contain
            height="90"
            transition="scale-transition"
            style="cursor: pointer;"
          />
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
  export default {
    name: 'Banner',
    data: () => ({
      items: [
        {
          src: require('@/assets/images/banner.png')
        }
      ],
    })
  }
</script>

<style lang="scss">
  .m-banner {
    position: relative;

    &__mobile-logo {
      position: absolute;
      width: 100%;
      transform: translateY(-45px);
    }
  }
</style>